import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from '@naf/button';
import { useGTMDataLayer } from '../../../hooks/useGTMDataLayer';
import { isInternalLink } from '../../../lib/isInternalLink';
import { LinkType } from '../../../../../types/LinkType';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';

const CTAButton = ({ cta, baseUrl }: { cta: LinkType; baseUrl: string }) => {
  const history = useHistory();
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const ctaTarget = useDocumentUrlWithNode(cta?.internalReference);

  const handleCTAButtonClick = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    ctaType: 'externalTarget' | 'internalReference' | 'relativeUrl',
    ctaUrl: string,
    ctaTitle: string,
  ) => {
    e.preventDefault();

    const commonEventData = {
      event: 'cta_click',
      cta_text: ctaTitle,
      cta_location: location.pathname,
      cta_url: ctaUrl,
      cta_position: 'content',
    };

    if (ctaType === 'externalTarget') {
      const linkUrlObject = new URL(ctaUrl);
      dataLayer?.push({
        ...commonEventData,
        cta_hostname: linkUrlObject.hostname,
      });
      window.open(ctaUrl, '_blank');
    } else if (ctaType === 'internalReference') {
      dataLayer?.push(commonEventData);
      history.push(ctaUrl);
    } else if (ctaType === 'relativeUrl') {
      const { isInternal, relativeLink } = isInternalLink(baseUrl, ctaUrl);
      if (isInternal) {
        dataLayer?.push(commonEventData);
        history.push(relativeLink);
      } else {
        const linkUrlObject = new URL(ctaUrl);
        dataLayer?.push({
          ...commonEventData,
          cta_hostname: linkUrlObject.hostname,
        });
        window.open(ctaUrl, '_blank');
      }
    }
  };

  if (cta?.externalTarget?.href) {
    return (
      <Button
        href={cta.externalTarget.href}
        target={cta.externalTarget.blank ? '_blank' : '_self'}
        onClick={(e) => handleCTAButtonClick(e, 'externalTarget', cta.externalTarget.href, cta.title)}
      >
        {cta.title}
      </Button>
    );
  }

  if (cta?.internalReference) {
    return (
      <Link to={ctaTarget} onClick={(e) => handleCTAButtonClick(e, 'internalReference', ctaTarget, cta.title ?? '')}>
        <Button type="button">{cta.title}</Button>
      </Link>
    );
  }

  if (cta?.relativeUrl?.url) {
    return (
      <Button onClick={(e) => handleCTAButtonClick(e, 'relativeUrl', cta.relativeUrl.url, cta.title)}>
        {cta.title}
      </Button>
    );
  }

  return null;
};

export default CTAButton;
